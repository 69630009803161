import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

// NextUI
import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import { Image, Divider, Link, Button, Input } from "@nextui-org/react";
import {User, Avatar, Chip } from "@nextui-org/react";

// Icons
import { HeartIcon, ChatBubbleOvalLeftIcon  } from '@heroicons/react/24/solid'

// Components
import CommentComponent from './CommentComponent';

function RoleplayComponent({roleplay}: any) {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  // state
  const [isLiked, setIsLiked] = useState<boolean>();
  const [likeCount, setLikeCount] = useState<any>();
  const [commentCount, setCommentCount] = useState<any>();
  const [openComments, setOpenComments] = useState(false);
  const [comments, setComments] = useState<any>([]);
  const [newCommentText, setNewCommentText] = useState("");

  useEffect(() => {
    setIsLiked(roleplay?.is_liked);
    setLikeCount(roleplay?.likes_count);
    setCommentCount(roleplay?.comments_count);
  }, [roleplay]);

  // functions
  const like = () => {
    http.post(`/roleplay/${roleplay?.id}/like`).then((res) => {
      setIsLiked(true);
      setLikeCount(likeCount + 1);
    });
  }

  const unlike = () => {
    http.delete(`/roleplay/${roleplay?.id}/like`).then((res) => {
      setIsLiked(false);
      setLikeCount(likeCount - 1);
    });
  }

  const newComment = () => {
    http.post(`/roleplay/${roleplay?.id}/comment`, {
      text: newCommentText
    }).then((res) => {
      res.data.comment.is_liked = false;
      res.data.comment.likes_count = 0;
      res.data.comment.comments_count = 0;
      res.data.comment.profile = AppContextState?.profile;

      setCommentCount(commentCount + 1);
      setComments([res.data.comment, ...comments]);
      setNewCommentText('');

      if(!openComments){
        toggleComments();
      }
    });
  }

  const toggleComments = () => {
    if(!openComments){
      http.get(`/roleplay/${roleplay?.id}`).then((res) => {
        setComments(res.data.roleplay.comments);
        setOpenComments(true);
      });
    } else {
      setComments([]);
      setOpenComments(false);
    }
  }

  return (
    <>
      <Card key={roleplay?.id} className='h-fit mx-auto'>
        <CardBody className='gap-2 p-2'>

          <div className='flex flex-row justify-between items-end'>
            {roleplay?.profile?.image ? (
              <User
                name={roleplay?.profile?.name}
                description={(
                  <Link className='hover:cursor-pointer text-xs' onClick={() => navigate(`/profile/@${roleplay?.profile?.handle}`)}>
                    @{roleplay?.profile?.handle}
                  </Link>
                )}
                avatarProps={{
                  src: `${process.env.REACT_APP_API}${roleplay?.profile?.image}`,
                  size: "sm"
                }}
              />
            ) : (
              <User
                name={roleplay?.profile?.name}
                description={(
                  <Link className='hover:cursor-pointer text-xs' onClick={() => navigate(`/profile/@${roleplay?.profile?.handle}`)}>
                    @{roleplay?.profile?.handle}
                  </Link>
                )}
                avatarProps={{
                  src: `${process.env.REACT_APP_API_BASE}/storage/tempProfile.png`,
                  size: "sm"
                }}
              />
            )}
            
            <p className="text-xs text-default-500">{new Date(roleplay?.created_at).toLocaleString()}</p>
          </div>

          <Divider/>
          
          <div className='hover:cursor-pointer' onClick={() => navigate(`/roleplay/${roleplay?.id}`)}>
            <div className='flex flex-row justify-center'>
              <h1 className='text-center text-2xl'>{roleplay?.title}</h1>
            </div>

            <div>
              <p>{(roleplay?.text?.length > 200) ? roleplay?.text?.slice(0, 200-1) + '...' : roleplay?.text}</p>
            </div>
          </div>
          

          {roleplay.tags?.length > 0 ? (
            <div className='pt-2 flex flex-row flex-wrap gap-2'>
              {roleplay.tags?.map((tag: any, index: number) => (
                <div key={tag?.id}>
                  <Chip size='sm'>{tag?.name}</Chip>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
          
          <Divider/>

          <div className='flex gap-3'>
            <Button size="sm" className='h-6 w-3/4' onClick={() => {toggleComments()}}>Comments ({commentCount})</Button>

            {isLiked ? (
              <Button size="sm" className='h-6 w-1/4' onClick={() => {unlike()}}>
                <HeartIcon className='w-5 text-rose-500'/>{likeCount}
              </Button>
            ) : (
              <Button size="sm" className='h-6 w-1/4' onClick={() => {like()}}>
                <HeartIcon className='w-5'/>{likeCount}
              </Button>
            )}
          </div>
          
          {openComments ? (
            <div>
              <div className='flex flex-row gap-3'>
                <Input size='sm' className='w-3/4' type="text" placeholder='New comment' value={newCommentText} onChange={(event) => {
                  setNewCommentText(event.target.value);
                }}/>
                <Button color="primary" className='w-1/4' size="sm" onClick={() => {newComment()}}>Post</Button>
              </div>

              <div>
                {comments?.map((comment: any, index: number) => (
                  <div key={comment?.id || index}>
                    <CommentComponent comment={comment}/>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}

        </CardBody>
      </Card>
    </>
  );
}

export default RoleplayComponent;
