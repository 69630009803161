import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A, useParams } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import { Button } from '@nextui-org/react';

import PostComponent from '../PostComponent';
import JournalComponent from '../JournalComponent';
import RoleplayComponent from '../RoleplayComponent';
import ImageComponent from '../ImageComponent';

function ProfileLikesComponent({ profile }: any) {
  const { AtHandle } = useParams();

  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [state, setState] = useState(false);

  const [likes, setLikes] = useState<any>([]);
  const [handle, setHandle] = useState('');
  const [isMe, setIsMe] = useState(false);

  useEffect(() => {
    if(AtHandle){
      const handleText = AtHandle?.split('@')[1];
      setHandle(handleText);

      if(handleText === AppContextState?.profile?.handle){
        setIsMe(true);
      } else {
        setIsMe(false);
      }

      http.get(`/like`, {
        params: {
          profile_id: profile?.id,
          order: 'latest'
        }
      }).then((res) => {
        setLikes(res?.data?.likes);

        res?.data?.likes?.forEach((like: any) => {
          console.log(like);
        });
      });
    }
	}, [profile]);

  return (
    <>
      <div className='flex flex-col gap-4 overflow-y-auto'>

        {isMe ? (
          <></>
        ) : (
          <></>
        )}

        {likes?.map((like: any, index: number) => (
          <div key={like.id}>
            {like?.likeable_type?.split("\\")[2] === "Post" && (
              <PostComponent post={like?.likeable}/>
            )}

            {like?.likeable_type?.split("\\")[2] === "Journal" && (
              <JournalComponent journal={like?.likeable}/>
            )}

            {like?.likeable_type?.split("\\")[2] === "Roleplay" && (
              <RoleplayComponent roleplay={like?.likeable}/>
            )}

            {like?.likeable_type?.split("\\")[2] === "Image" && (
              <ImageComponent image={like?.likeable}/>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default ProfileLikesComponent;
