import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import {User, Divider, Link, Image, Button } from "@nextui-org/react";
import {Tabs, Tab} from "@nextui-org/react";
import {Avatar, AvatarGroup, Textarea} from "@nextui-org/react";
import {Chip, Input} from "@nextui-org/react";

import ImageUploadComponent from '../../Components/ImageUploadComponent';
import ImageComponent from '../../Components/ImageComponent';
import TagComponent from '../../Components/TagComponent';

function ImagesPage() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [images, setImages] = useState<any>([]);
  const [descriptionSearch, setDescriptionSearch] = useState<any>([]);
  const [tagSearch, setTagSearch] = useState<any>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    searchImages();
  }, []);

  const searchImages = () => {
    http.get(`/image`, {
      params: {
        description: descriptionSearch,
        tags: tagSearch?.map((tag: any) => tag.id),
        order: 'latest',
        page: 1,
        quantity: 30,
      }
    }).then((res) => {
      setImages(res.data.images);
    });
  }

  const loadMore = () => {
    http.get(`/image`, {
      params: {
        description: descriptionSearch,
        tags: tagSearch?.map((tag: any) => tag.id),
        order: 'latest',
        page: page + 1,
        quantity: 30,
      }
    }).then((res) => {
      setImages([...images, ...res.data.images]);
      setPage(page + 1);
    });
  }

  return (
    <>
      <div className="min-h-[calc(100vh-64px-64px)] w-full py-5">
        <Card>
          <CardHeader className='flex flex-row gap-2 items-start'>
            <div className='basis-1/4 flex flex-col gap-2'>
              <Input size='sm' type="text" label="Search Description" value={descriptionSearch} onChange={(event) => {
                setDescriptionSearch(event.target.value);
              }}/>
            </div>
            <div className='basis-2/4 flex flex-col gap-2'>
              <TagComponent selectedTagsInit={[]} onTagSelect={(tags: any) => {
                setTagSearch(tags);
              }}/>
            </div>
            <div className='basis-1/4 flex flex-col'>
              <Button color='primary' onClick={() => searchImages()}>Search</Button>
            </div>
          </CardHeader>
          <Divider/>
          <CardBody className='flex flex-row flex-wrap justify-around p-2'>

            {/* Images */}
            {images?.map((image: any) => (
              <div key={image?.id} className='p-3 md:basis-1/2 lg:basis-1/3 flex flex-row flex-wrap justify-around'>
                <ImageComponent image={image} images={images} setImages={setImages}/>
              </div>
            ))}

            <div className='w-full flex flex-row justify-center'>
              {images?.length % 30 === 0 ? (
                <Button onClick={() => loadMore()}>Load More</Button>
              ) : (
                <></>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default ImagesPage;
