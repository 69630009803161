import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import {Tabs, Tab} from "@nextui-org/react";
import { Image, Divider, Button, Link } from "@nextui-org/react";
import {Avatar, AvatarGroup} from "@nextui-org/react";

import PostComponent from './PostComponent';
import JournalComponent from './JournalComponent';
import RoleplayComponent from './RoleplayComponent';
import ImageComponent from './ImageComponent';

function FeedComponent() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [profiles, setProfiles] = useState([]);
  const [posts, setPosts] = useState([]);
  const [journals, setJournals] = useState([]);
  const [roleplays, setRoleplays] = useState([]);
  const [images, setImages] = useState([]);
  const [likes, setLikes] = useState([]);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    http.get('/feed', {
      params: {
        friends: false,
        follows: true,
        post_include: true,
        post_limit: 30,
        journal_include: true,
        journal_limit: 30,
        roleplay_include: true,
        roleplay_limit: 30,
        like_include: true,
        like_limit: 30,
        comment_include: true,
        comment_limit: 30,
        image_include: true,
        image_limit: 30,
      }
    }).then((res) => {
      setProfiles(res.data.profiles);
      setPosts(res.data.posts);
      setJournals(res.data.journals);
      setRoleplays(res.data.roleplays);
      setImages(res.data.images);
      setLikes(res.data.likes);
      setComments(res.data.comments);
    });
	}, []);

  return (
    <>
      <Card>
        <CardHeader>
          <div className='text-center text-2xl font-bold w-full'>
            <h1>Feed</h1>
            <AvatarGroup max={50} className='my-3'>
              {profiles?.map((profile: any, index: number) => {
                if(profile?.image){
                  return (
                    <Avatar 
                      key={profile?.id} 
                      src={`${process.env.REACT_APP_API}${profile?.image}`}
                      onClick={() => navigate(`/profile/@${profile?.handle}`)}
                    />
                  )
                }

                return (
                  <Avatar 
                    key={profile?.id} 
                    src={`${process.env.REACT_APP_API_BASE}/storage/tempProfile.png`}
                    onClick={() => navigate(`/profile/@${profile?.handle}`)}
                  />
                )
                })}
            </AvatarGroup>
          </div>
        </CardHeader>
        <Divider/>
        <CardBody>
          <Tabs aria-label="Options" fullWidth>
            <Tab key="posts" title="Posts">
              <Card>
                <CardBody className='gap-3'>
                  {posts?.map((post: any, index: number) => (
                    <div key={post?.id}>
                      <PostComponent post={post}/>
                    </div>
                  ))}
                </CardBody>
              </Card>  
            </Tab>
            <Tab key="journals" title="Journals">
              <Card>
                <CardBody>
                  {journals?.map((journal: any, index: number) => (
                    <div key={journal?.id}>
                      <JournalComponent journal={journal}/>
                    </div>
                  ))}
                </CardBody>
              </Card>  
            </Tab>
            <Tab key="roleplays" title="Roleplays">
              <Card>
                <CardBody>
                  {roleplays?.map((roleplay: any, index: number) => (
                    <div key={roleplay?.id}>
                      <RoleplayComponent roleplay={roleplay}/>
                    </div>
                  ))}
                </CardBody>
              </Card>  
            </Tab>
            <Tab key="images" title="Images">
              <Card>
                <CardBody>
                  {images?.map((image: any, index: number) => (
                    <div key={image?.id}>
                      <ImageComponent image={image}/>
                    </div>
                  ))}
                </CardBody>
              </Card>  
            </Tab>
            <Tab key="likes" title="Likes">
              <Card>
                <CardBody>
                  {likes?.map((like: any, index: number) => (
                    <div key={like?.id}>
                      <p>{like.likeable_type}</p>
                      <br></br>
                    </div>
                  ))}
                </CardBody>
              </Card>  
            </Tab>
            <Tab key="comments" title="Comments">
              <Card>
                <CardBody>
                  {comments?.map((comment: any, index: number) => (
                    <div key={comment?.id}>
                      <p>{comment.commentable_type}</p>
                      <br></br>
                    </div>
                  ))}
                </CardBody>
              </Card>  
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
    </>
  );
}

export default FeedComponent;
