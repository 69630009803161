import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link as A } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import {User, Divider, Avatar, Link, Image, Button, Chip } from "@nextui-org/react";

import ProfileCardComponent from '../../Components/ProfileCardComponent';

function ProfileRequestsPage() {
  const { AtHandle } = useParams();
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [isMe, setIsMe] = useState(false);
  const [profile, setProfile] = useState<any>(null);
  const [requests, setRequests] = useState<any>([]);
  const [requesting, setRequesting] = useState<any>([]);

  useEffect(() => {
    if(AtHandle){
      const handle = AtHandle?.split('@')[1];

      if(handle === AppContextState?.profile?.handle){
        setIsMe(true);
      }
  
      http.get(`/profile/${handle}/friend/request`).then((res) => {
        console.log(res.data);
        setProfile(res.data.profile);
        setRequests(res.data.profile.friend_requests);
        setRequesting(res.data.profile.friend_requesting);
      });
    }
	}, [AtHandle, AppContextState.profile]);

  const approve = (approvedProfile: any) => {
    http.post(`/profile/${approvedProfile.handle}/friend/request/approve`).then((res) => {
      console.log(res.data);
    });
  }

  const deny = (deniedProfile: any) => {
    http.post(`/profile/${deniedProfile.handle}/friend/request/deny`).then((res) => {
      console.log(res.data);
    });
  }

  return (
    <div className="min-h-[calc(100vh-64px-64px)] w-full py-5">
      <Card>
        <CardHeader className='flex flex-row'>
          <div className='basis-1/3'>
            <User   
              name={AppContextState?.profile?.name}
              description={(
                <Link size="sm" className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${AppContextState?.profile?.handle}`)}>
                  @{AppContextState?.profile?.handle}
                </Link>
              )}
              avatarProps={{
                src: "https://avatars.githubusercontent.com/u/30373425?v=4"
              }}
            />
          </div>
          <div className='basis-1/3 text-center text-2xl font-bold'>
              <h1>Requests</h1>
          </div>
          <div className='basis-1/3'>

          </div>
        </CardHeader>

        <Divider/>

        {/* List of profiles */}
        <CardBody className='flex flex-row flex-wrap'>

          {requesting.map((profile: any) => (
            <div key={profile?.id} className='p-3 md:basis-1/2 lg:basis-1/3'>
              <Card className="h-full basis-full">
                <CardHeader className="flex flex-col gap-3">
                  {/* Name and handle */}
                  <div className='w-full flex justify-between'>
                    <div className="flex flex-col">
                      <p className="text-md">{profile?.name}</p>
                      <Link size="sm" className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${profile?.handle}`)}>@{profile?.handle}</Link>
                    </div>

                    {/* Follow find */}
                    <div className="flex justify-center items-center gap-3">
                      <Button color="primary" size="sm" onClick={() => approve(profile)}>Approve</Button>
                      <Button color="danger" size="sm" onClick={() => deny(profile)}>Deny</Button>
                    </div>
                  </div>

                  {/* Age gender orientation */}
                  <div className='w-full flex flex-row justify-around'>
                    <p className="text-small text-default-500">Age: {profile?.age}</p>
                    <p className="text-small text-default-500">Gender: {profile?.gender}</p>
                    <p className="text-small text-default-500">Orientation: {profile?.orientation}</p>
                  </div>
                </CardHeader>

                <Divider/>

                {/* Main profile */}
                <CardBody className='flex flex-col gap-3'>
                  {/* Profile image /storage/tempProfile.png */}
                  {profile.image ? (
                    <Image
                      alt="NextUI hero Image"
                      src={`${process.env.REACT_APP_API}${profile?.image}`}
                      onClick={() => navigate(`/profile/@${profile?.handle}`)}
                      className='hover:cursor-pointer'
                    />
                  ) : (
                    <Image
                      alt="NextUI hero Image"
                      src={`${process.env.REACT_APP_API_BASE}/storage/tempProfile.png`}
                      onClick={() => navigate(`/profile/@${profile?.handle}`)}
                      className='hover:cursor-pointer'
                    />
                  )}

                  {/* Friends following followers */}
                  <div className='flex flex-row justify-around text-sm'>
                    <Link className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${profile?.handle}/friends`)}>Friends ({profile?.friends_count})</Link>
                    <Link className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${profile?.handle}/followers`)}>Followers ({profile?.followers_count})</Link>
                    <Link className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${profile?.handle}/following`)}>Following ({profile?.following_count})</Link>
                  </div>

                  <Divider/>

                  {/* Bio */}
                  <div>
                    <p className=' rounded-xl p-1'>{profile?.bio}</p>
                  </div>

                  <div className='flex flex-row flex-wrap gap-2'>
                    {profile.tags?.map((tag: any, index: number) => (
                      <div key={tag?.id}>
                        <Chip>{tag?.name}</Chip>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </div>
          ))}
        </CardBody>
      </Card>
    </div>
  );
}

export default ProfileRequestsPage;
