import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import {User, Divider, Link, Image, Button } from "@nextui-org/react";
import {Tabs, Tab} from "@nextui-org/react";
import {Avatar, AvatarGroup, Textarea} from "@nextui-org/react";
import {Chip, Input} from "@nextui-org/react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure} from "@nextui-org/react";

import { HeartIcon, ChatBubbleOvalLeftIcon  } from '@heroicons/react/24/solid'

import CommentComponent from './CommentComponent';

function ImageComponent({image, images, setImages}: any) {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  const [isLiked, setIsLiked] = useState<boolean>();
  const [likeCount, setLikeCount] = useState<any>();
  const [commentCount, setCommentCount] = useState<any>();
  const [openComments, setOpenComments] = useState(false);
  const [comments, setComments] = useState<any>([]);
  const [newCommentText, setNewCommentText] = useState("");
  const [isMe, setIsMe] = useState(false);

  useEffect(() => {
    setIsLiked(image?.is_liked);
    setLikeCount(image?.likes_count);
    setCommentCount(image?.comments_count);

    if(AppContextState.profile.id === image.profile_id){
      setIsMe(true);
    }
  }, [image]);

  const updateProfilePic = (image: any) => {
    console.log(image);
    http.patch(`/profile/${image.profile.handle}`, {
      image: image.uri,
    }).then((res) => {
      AppContextState.profile.image = image.uri;
      AppContextState.updateProfile(AppContextState.profile);

      AppContextState.updateProfiles(AppContextState.profiles.map((profile: any) => {
        if(profile.handle === image.profile.handle){
          profile.image = image.uri;
        }
        return profile;
      }));

      console.log(res.data);
    });
  }

  const removeImage = (image: any) => {
    http.delete(`/image/${image.file}`).then((res) => {
      console.log(res);
      setImages(images.filter((imageInstance: any) => {
        if(imageInstance.id !== image.id){
          return imageInstance;
        }
        return false;
      }));
    });
  }

  const likeImage = () => {
    http.post(`/image/${image?.id}/like`).then((res) => {
      setIsLiked(true);
      setLikeCount(likeCount + 1);
    });
  }

  const unlikeImage = () => {
    http.delete(`/image/${image?.id}/like`).then((res) => {
      setIsLiked(false);
      setLikeCount(likeCount - 1);
    });
  }

  const newComment = () => {
    http.post(`/image/${image?.id}/comment`, {
      text: newCommentText
    }).then((res) => {
      res.data.comment.is_liked = false;
      res.data.comment.likes_count = 0;
      res.data.comment.comments_count = 0;
      res.data.comment.profile = AppContextState?.profile;

      setCommentCount(commentCount + 1);
      setComments([res.data.comment, ...comments]);
      setNewCommentText('');

      if(!openComments){
        toggleImageComments();
      }
    });
  }

  const toggleImageComments = () => {
    console.log(image);
    if(!openComments){
      http.get(`/image/${image?.id}`).then((res) => {
        setComments(res.data.image.comments);
        setOpenComments(true);
      });
    } else {
      setComments([]);
      setOpenComments(false);
    }
  }

  return (
    <>
      <Card className='w-fit'>

        <CardHeader className='flex flex-row justify-between'>
          {image?.profile?.image ? (
            <User
              name={image?.profile?.name}
              description={(
                <Link className='hover:cursor-pointer text-xs' onClick={() => navigate(`/profile/@${image?.profile?.handle}`)}>
                  @{image?.profile?.handle}
                </Link>
              )}
              avatarProps={{
                src: `${process.env.REACT_APP_API}${image?.profile?.image}`,
                size: "sm"
              }}
            />
          ) : (
            <User
              name={image?.profile?.name}
              description={(
                <Link className='hover:cursor-pointer text-xs' onClick={() => navigate(`/profile/@${image?.profile?.handle}`)}>
                  @{image?.profile?.handle}
                </Link>
              )}
              avatarProps={{
                src: `${process.env.REACT_APP_API_BASE}/storage/tempProfile.png`,
                size: "sm"
              }}
            />
          )}
          {isMe && (
            <>
              <Button size='sm' onClick={() => {
                updateProfilePic(image);
              }}>Set As Profile</Button>
              <Button size='sm' color='danger' onClick={() => {
                removeImage(image)
              }}>Delete</Button>
            </>
          )}
        </CardHeader>

        <Divider/>

        <CardBody className='gap-1'>
          <Image
            width={300}
            alt="NextUI hero Image"
            src={`${process.env.REACT_APP_API}${image?.uri}`}
            onClick={onOpen}
          />
        </CardBody>

        <Divider/>

        <CardFooter>
          <p>{image?.description}</p>

          <Button size="sm" className='h-6 basis-3/4' onClick={() => onOpen()}>Comments ({commentCount})</Button>

          {isLiked ? (
            <Button size="sm" className='h-6 w-1/4' onClick={() => {unlikeImage()}}>
              <HeartIcon className='w-5 text-rose-500'/>{likeCount}
            </Button>
          ) : (
            <Button size="sm" className='h-6 w-1/4' onClick={() => {likeImage()}}>
              <HeartIcon className='w-5'/>{likeCount}
            </Button>
          )}
        </CardFooter>

      </Card>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {image?.profile?.image ? (
                  <User
                    name={image?.profile?.name}
                    description={(
                      <Link className='hover:cursor-pointer text-xs' onClick={() => navigate(`/profile/@${image?.profile?.handle}`)}>
                        @{image?.profile?.handle}
                      </Link>
                    )}
                    avatarProps={{
                      src: `${process.env.REACT_APP_API}${image?.profile?.image}`,
                      size: "sm"
                    }}
                  />
                ) : (
                  <User
                    name={image?.profile?.name}
                    description={(
                      <Link className='hover:cursor-pointer text-xs' onClick={() => navigate(`/profile/@${image?.profile?.handle}`)}>
                        @{image?.profile?.handle}
                      </Link>
                    )}
                    avatarProps={{
                      src: `${process.env.REACT_APP_API_BASE}/storage/tempProfile.png`,
                      size: "sm"
                    }}
                  />
                )}
              </ModalHeader>

              <Divider/>

              <ModalBody className='flex flex-row justify-center'>
                <Image
                  width={500}
                  alt="NextUI hero Image"
                  src={`${process.env.REACT_APP_API}${image?.uri}`}
                  onClick={onOpen}
                />
              </ModalBody>

              <ModalFooter>
                <div className='flex flex-col w-full gap-2'>
                  <div className='flex flex-row gap-2'>
                    <Button size="sm" className='h-6 basis-3/4' onClick={() => {toggleImageComments()}}>Comments ({commentCount})</Button>

                    {isLiked ? (
                      <Button size="sm" className='h-6 basis-1/4' onClick={() => {unlikeImage()}}>
                        <HeartIcon className='w-5 text-rose-500'/>{likeCount}
                      </Button>
                    ) : (
                      <Button size="sm" className='h-6 basis-1/4' onClick={() => {likeImage()}}>
                        <HeartIcon className='w-5'/>{likeCount}
                      </Button>
                    )}
                  </div>

                  {openComments ? (
                    <div className='flex flex-col'>
                      <div className='flex flex-row gap-3'>
                        <Input size='sm' className='w-3/4' type="text" placeholder='New comment' value={newCommentText} onChange={(event) => {
                          setNewCommentText(event.target.value);
                        }}/>
                        <Button color="primary" className='w-1/4' size="sm" onClick={() => {newComment()}}>Post</Button>
                      </div>

                      <div>
                        {comments?.map((comment: any, index: number) => (
                          <div key={comment?.id || index}>
                            <CommentComponent comment={comment}/>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default ImageComponent;
