import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HomePage from './Pages/HomePage';
import LoginPage from './Pages/LoginPage';
import SignUpPage from './Pages/SignUpPage';

import ProfilesPage from './Pages/Profile/index';
import ProfilePage from './Pages/Profile/Profile';
import ProfileFriendsPage from './Pages/Profile/Friends';
import ProfileFollowingPage from './Pages/Profile/Following';
import ProfileFollowersPage from './Pages/Profile/Followers';
import ProfileImagesPage from './Pages/Profile/Images';
import ProfileRequestsPage from './Pages/Profile/Requests';

import JournalsPage from './Pages/Journal';
import JournalPage from './Pages/Journal/Journal';
import JournalEditPage from './Pages/Journal/Edit';
import JournalCreatePage from './Pages/Journal/Create';

import CommentsPage from './Pages/Comment';
import ImagesPage from './Pages/Image';
import PostsPage from './Pages/Post';
import RoleplaysPage from './Pages/Roleplay';
import ChatPage from './Pages/ChatPage';

function Router() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />

        {/* Profile */}
        <Route path="/profile" element={<ProfilesPage />} />
        <Route path="/profile/:AtHandle" element={<ProfilePage />} />
        <Route path="/profile/:AtHandle/friends" element={<ProfileFriendsPage />} />
        <Route path="/profile/:AtHandle/requests" element={<ProfileRequestsPage />} />
        <Route path="/profile/:AtHandle/following" element={<ProfileFollowingPage />} />
        <Route path="/profile/:AtHandle/followers" element={<ProfileFollowersPage />} />
        <Route path="/profile/:AtHandle/images" element={<ProfileImagesPage />} />

        {/* Posts */}
        <Route path="/post" element={<PostsPage />} />

        {/* Journals */}
        <Route path="/journal" element={<JournalsPage />} />
        <Route path="/journal/create" element={<JournalCreatePage />} /> 
        <Route path="/journal/:journalId" element={<JournalPage />} />
        <Route path="/journal/:journalId/edit" element={<JournalEditPage />} />

        {/* Roleplays */}
        <Route path="/roleplay" element={<RoleplaysPage />} /> 
        <Route path="/roleplay/:roleplayId" element={<RoleplaysPage />} />
        <Route path="/roleplay/:roleplayId/edit" element={<RoleplaysPage />} />

        {/* Images */}
        <Route path="/image" element={<ImagesPage />} /> 
        <Route path="/image/:imageId" element={<ImagesPage />} />
        <Route path="/image/:imageId/edit" element={<ImagesPage />} />

        {/* Comments */}
        <Route path="/comment" element={<CommentsPage />} />

        <Route path="/chat" element={<ChatPage />} />
      </Routes>
    </>
  );
}

export default Router;
